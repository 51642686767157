@keyframes animation-spin {
	to {
		transform: rotate(360deg);
	}
}

.loading-content {
	$color-bg: var(--color-white);
	$color-fg: currentColor;

	display: flex; justify-content: center; align-items: center; position: relative; color: currentColor; flex: 0 0 auto; overflow: hidden;

	svg { mix-blend-mode: darken; }

	&.is-spinner {
		svg { animation: animation-spin calc(var(--ui-speed) * 4) linear infinite; }
	}

	&.is-spinner & {
		&__gradient { background: conic-gradient(from 270deg at 50% 50%, $color-bg 0deg, $color-bg 90deg, $color-fg 180deg, $color-bg 190deg, $color-bg, 270deg, $color-bg); }
	}

	&.is-progressbar & {
		&__elem { stroke: $color-fg; }
	}

	&.layout-absolute {
		position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: var(--color-white); height: auto !important;
	}

	&.size-default {
		height: 160px;

		svg { width: 160px; height: 160px; }
	}

	&.size-tiny {
		height: 12px;

		svg { width: 12px; height: 12px; }
	}

	&.size-small {
		height: 24px;

		svg { width: 24px; height: 24px; }
	}

	&.size-medium {
		height: 160px;

		svg { width: 128px; height: 128px; }
	}

	&__gradient {
		width: 100%;
		height: 100%;
		background: red;
	}

	&__fill { fill: var(--color-white); }

	&__circle { stroke: $color-bg; }

	&__elem { stroke: #000000; }

	&__group { mix-blend-mode: lighten; }

	&__label { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); font-size: 20px; line-height: 30px; }

	@media print {
		display: none;
	}
}
