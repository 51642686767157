@import "@/styles/mixins";

.checkbox-with-label {
	display: flex; align-items: flex-start; gap: var(--gridunit-tiny); user-select: none; cursor: default; box-sizing: border-box; padding: 2px 0;

	> *:first-child { margin-top: 4px; }

	&__label {
		flex: 1 1 auto; display: flex; flex-direction: column; overflow: hidden; text-overflow: ellipsis;

		&__text {
			&.has-error {
				color: var(--color-danger);

				A { color: var(--color-danger); }
			}
		}
	}
}
