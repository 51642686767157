@import "@/styles/mixins";

:import("@/components/ui/Well/styles.module.scss") {
  well: well;
}

:import("@/components/ui/Details/styles.module.scss") {
  details: details;
}

:import("@/components/ui/SimpleImage/styles.module.scss") {
  simple-image: simple-image;
}

:import("@/components/ui/ListPersons/styles.module.scss") {
  list-persons__item: list-persons__item;
}

:import("@/components/ui/ListFormulas/styles.module.scss") {
  list-formulas: list-formulas;
}

.layout-split {
  @include desktop {
    display: grid; gap: var(--gridunit);

    &:has(> :first-child:nth-last-child(1)) {
      grid-template-columns: 1fr;
    }

    &:has(> :first-child:nth-last-child(2)) {
      grid-template-columns: 1fr 1fr;
    }

    &:has(> :first-child:nth-last-child(3)) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &:has(> :first-child:nth-last-child(4)) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @include not-desktop { display: flex; flex-direction: column; }

  .simple-image + * { @include default-margin; }


  @include not-desktop {
    > * + * { margin-top: 1em; }
  }

  // Default layout

  &.layout-default {
    margin: 0;

    @include desktop {
      &:has(.list-persons__item) {
        grid-template-columns: 2fr 1fr !important; @include default-margin;

        > * > *:first-child { margin-top: 0 !important; }
      }

      &:has(.details) {
        grid-template-columns: 384px 1fr;

        > * { margin-top: 0 !important; }
      }
    }

    &:has(.details) {
      > * { margin-top: 0 !important; }
    }

    &:has(.list-formulas) {
      @include desktop {
        gap: var(--gridunit-large); position: relative;

        &:before { content: ""; width: 2px; position: absolute; left: 50%; top: var(--gridunit-large); bottom: 0; transform: translate(-50%, 0); z-index: 1; pointer-events: none; background-color: var(--color-primary-500); }
      }
    }

    > * {
      > .well:first-child { margin-top: var(--gridunit-large); }
    }
  }

  // Article layout

  &.layout-article {
    @include block-margin;

    > * {
      > *:first-child { margin-top: 0; }
    }
  }

  // Company & Person profiles

  &.layout-profile {
    @include not-desktop {
      > * { position: relative; }
    }

    @include desktop {
      position: relative;
      grid-template-columns: 280px 1fr !important;

      > *:last-child {
        margin-left: calc(var(--gridunit-small) * -1); padding-left: var(--gridunit-small); margin-right: calc(var(--gridunit-small) * -1); padding-right: var(--gridunit-small);
        max-width: min(calc(var(--content-maxwidth) - 280px - var(--gridunit)), calc(100vw - 280px - var(--gridunit) - var(--gridunit) - var(--gridunit) - var(--scrollbar-width)));
      }
    }

    @media print {
      display: block;

      > *:first-child { display: none; }

      > * { width: 100% !important; padding: 0 !important; margin: 0 !important; max-width: 100% !important; }
    }
  }

  // Filter tool

  &.layout-filter {
    > * {
      > .well:first-child { margin-top: var(--gridunit-large); }
    }

    @include not-desktop {
      display: flex; flex-direction: column-reverse;

      > * { margin-top: 0; }

      .well:first-child { margin-top: var(--gridunit); }
    }
  }

  // Certificates

  &.layout-certificates {
    display: grid !important; grid-template-columns: 1fr 150px !important; margin: 0 !important; gap: var(--gridunit);

    @include mobile { display: flex !important; flex-direction: column-reverse; }
  }

  // API

  &.layout-api {
    display: grid !important; grid-template-columns: 1fr 350px !important; margin: 0 !important; gap: var(--gridunit);

    > * > * { margin-top: var(--gridunit); flex-grow: 0;}

    @include mobile { display: flex !important; flex-direction: column; }

    H2, H3 { border-bottom: 1px solid var(--color-neutral-800); }
  }

  // new

  &.layout-report {
    display: grid !important; grid-template-columns: 1fr min-content !important; margin: 0 !important; gap: var(--gridunit);

    @include mobile { display: flex !important; flex-direction: column-reverse; }
  }
}