@import "@/styles/mixins";

.list-formulas {
  @include default-margin;

  @include mobile {
    font-size: 12px; line-height: 16px;

    > LI { gap: var(--gridunit-tiny) !important; }
  }

  > LI {
    + LI { margin-top: var(--gridunit); }

    display: grid; grid-template-areas: "a d b b" "a d e c"; grid-template-columns: auto auto auto auto;
    column-gap: var(--gridunit-small); row-gap: 12px; align-items: center; justify-content: center; align-content: center; justify-items: center;

    > * { display: flex; border-radius: var(--radius-default); padding: var(--gridunit-tiny) var(--gridunit-small); }
  }

  path { fill: currentColor; }

  &__a { grid-area: a; background-color: var(--color-neutral-50); color: var(--color-text); }

  &__b { grid-area: b; box-shadow: inset 0 0 0 1px var(--color-neutral-50); }

  &__c { grid-area: c; box-shadow: inset 0 0 0 1px var(--color-neutral-50); }

  &__d { grid-area: d; background-color: var(--color-neutral-50); color: var(--color-text); border-radius: 50% !important; padding: var(--gridunit-tiny) !important; }

  &__e { grid-area: e; background-color: var(--color-neutral-50); color: var(--color-text); border-radius: 50% !important; padding: var(--gridunit-tiny) !important; }

  // Themes

  .theme-default & {
      &__a,
      &__d,
      &__e { background-color: var(--color-neutral-50); color: var(--color-text); }

      &__b,
      &__c { box-shadow: inset 0 0 0 1px var(--color-neutral-50) !important; }
    }

  .theme-red & {
    &__a,
    &__d,
    &__e { background-color: var(--color-alert-500); color: var(--color-white); }

    &__b,
    &__c { box-shadow: inset 0 0 0 1px var(--color-alert-500) !important; }
  }

  .theme-yellow & {
    &__a,
    &__d,
    &__e { background-color: var(--color-warning-400); color: var(--color-text); }

    &__b,
    &__c { box-shadow: inset 0 0 0 1px var(--color-warning-400) !important; }
  }

  .theme-blue & {
    &__a,
    &__d,
    &__e { background-color: var(--color-primary-500); color: var(--color-white); }

    &__b,
    &__c { box-shadow: inset 0 0 0 1px var(--color-primary-500) !important; }
  }
}