@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__element: button__element;
}

.buttons {
  flex: 0 0 auto !important; max-width: 100%;
  padding: 0; margin: var(--gridunit) 0 0 0; list-style: none; display: flex; flex-direction: row; flex-wrap: wrap; row-gap: 12px; column-gap: var(--gridunit-small);

  > LI {
    margin: 0; flex: 0 0 auto; display: flex; max-width: 100%; text-overflow: ellipsis;

    .button {
      display: block; flex: 1 1 auto;

      &__element { width: 100%; }
    }

    &.title {
      color: var(--color-primary-500); align-self: center; text-align: center; flex: 1 1 auto; font-weight: var(--medium); font-size: 24px; line-height: normal; align-items: center; justify-content: center;
    }
  }

  &.layout-default {
    @include mobile {
      justify-content: center; flex-direction: column;

      > LI { flex: 0 0 auto; }

      > .primary { order: 1; }

      > .secondary { order: 2; }

      .button { width: 100%; max-width: 100%; }
    }
    @include not-mobile {
      justify-content: flex-start;

      > .primary { margin-left: auto; }

      > .secondary { margin-right: auto; }
    }
  }

  &.layout-horizontal {
    justify-content: flex-start;
  }

  &.layout-centered {
    flex-direction: row; justify-content: center; align-items: center;
    @include desktop { flex-wrap: nowrap; }
  }

  &.layout-vertical {
    flex-direction: column; justify-content: center;

    > .primary { order: 1; }

    > .secondary { order: 2; }
  }

  &.layout-vertical-wide {
    flex-direction: column; justify-content: center;

    > .primary { order: 1; }

    > .secondary { order: 2; }

    > LI { flex: 1 1 auto; }
  }

  &.size-small {
    flex-wrap: nowrap; flex-direction: row; row-gap: var(--gridunit-tiny); column-gap: var(--gridunit-tiny);
    @include mobile { flex-direction: column; }
  }

  &.sticky-mobile {
    @include mobile {
      position: sticky; left: 0; right: 0; bottom: 0;
      margin: 0 0 calc(var(--gridunit-small) * -1) 0; padding: var(--gridunit) var(--gridunit-small);
      background: linear-gradient(to bottom, transparent, var(--site-bg) 50%, var(--site-bg));
    }
  }

  @media print { display: none; }
}
