@import "@/styles/mixins";

.animations {
  overflow: hidden; pointer-events: none; line-height: 0;

  path,
  circle,
  g { transform-origin: 50% 50%; transform-box: fill-box; }

  svg { display: block; position: relative; }

  &.animation01 {
    width: 720px; height: 320px;

    svg { width: 720px; height: 320px; }

    @include mobile {
      width: 340px; height: 160px;

      :global(.element01c) { opacity: 0 !important; }

      :global(.element01d) { opacity: 0 !important; }

      :global(.element01g) { opacity: 0 !important; }

      :global(.element01h) { opacity: 0 !important; }

      :global(.element01i) { opacity: 0 !important; }

      :global(.element01j) { opacity: 0 !important; }

      :global(.element01k) { opacity: 0 !important; }

      :global(.element01l) { opacity: 0 !important; }

      :global(.element02c) { opacity: 0 !important; }

      :global(.element02d) { opacity: 0 !important; }

      :global(.element02e) { opacity: 0 !important; }

      :global(.element02f) { opacity: 0 !important; }

      :global(.element02g) { opacity: 0 !important; }

      :global(.element02h) { opacity: 0 !important; }

      :global(.element02i) { opacity: 0 !important; }

      :global(.element02j) { opacity: 0 !important; }

      :global(.element02k) { opacity: 0 !important; }

      :global(.element02l) { opacity: 0 !important; }
    }
  }

  &.animation01.animating {
    :global(.element01) { animation: animation1 none 1200ms var(--ui-easing) 0ms; }

    :global(.element02) { animation: animation2 none 1200ms var(--ui-easing) 0ms; }
  }

  &.animation02 {
    width: 320px; height: 320px;

    svg { width: 320px; height: 320px; }

    @include mobile {
      width: 200px; height: 160px;
    }
  }

  &.animation02.animating {
    :global(path, g, circle) { animation: animation3 none 1000ms var(--ui-easing) 0ms; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element03) { animation-delay: 0ms; }

    :global(.element06) { animation-delay: 0ms; }

    :global(.element10) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 100ms; }

    :global(.element05) { animation-delay: 100ms; }

    :global(.element09) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 200ms; }

    :global(.element08) { animation-delay: 200ms; }

    :global(.element07) { animation-delay: 300ms; }
  }

  &.animation03 {
    width: 400px; height: 400px;

    svg { width: 400px; height: 400px; }

    @include not-desktop {
      width: 240px; height: 240px;

      svg { margin: -160px 0 0 -160px; }

      :global(.element01),
      :global(.element02),
      :global(.element03),
      :global(.element04),
      :global(.element05),
      :global(.element06),
      :global(.element07),
      :global(.element08),
      :global(.element09),
      :global(.element11),
      :global(.element12),
      :global(.element13) { opacity: 0 !important; }
    }
  }

  &.animation03.animating {
    :global(path, g, circle) { animation: animation4 none 500ms var(--ui-easing) 0ms; transform-origin: 50% 50%; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 50ms; }

    :global(.element03) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 150ms; }

    :global(.element05) { animation-delay: 200ms; }

    :global(.element06) { animation-delay: 250ms; }

    :global(.element07) { animation-delay: 300ms; }

    :global(.element08) { animation-delay: 350ms; }

    :global(.element09) { animation-delay: 400ms; }

    :global(.element10) { animation-delay: 450ms; }

    :global(.element11) { animation-delay: 500ms; }

    :global(.element12) { animation-delay: 550ms; }

    :global(.element13) { animation-delay: 600ms; }

    :global(.element14) { animation-delay: 650ms; }

    :global(.element15) { animation-delay: 700ms; }

    @include mobile {
      :global(.element06) { animation-delay: 0ms; }

      :global(.element09) { animation-delay: 50ms; }

      :global(.element10) { animation-delay: 100ms; }

      :global(.element13) { animation-delay: 150ms; }

      :global(.element14) { animation-delay: 200ms; }

      :global(.element15) { animation-delay: 250ms; }
    }
  }

  &.animation04 {
    width: 720px; height: 320px;

    svg { width: 720px; height: 320px; }

    @include mobile {
      width: 320px; height: 160px;

      :global(.element01d) { opacity: 0 !important; }
    }
  }

  &.animation04.animating {
    :global(path, g, circle) { animation: animation5 none 1500ms var(--ui-easing) 0ms; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 50ms; }

    :global(.element03) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 150ms; }

    :global(.element05) { animation-delay: 200ms; }
  }

  &.animation05 {
    width: 320px; height: 320px;

    svg { width: 320px; height: 320px; }

    @include mobile {
      width: 200px; height: 160px;
    }
  }

  &.animation05.animating {
    :global(g) { transform-origin: 50% 50%; animation: animation3 none 1000ms var(--ui-easing) 0ms; }

    :global(path[class]) { transform-origin: 50% 50%; animation: reverse animation3 none 1000ms var(--ui-easing) 0ms; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 50ms; }

    :global(.element03) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 150ms; }

    :global(.element05) { animation-delay: 200ms; }

    :global(.element06) { animation-delay: 250ms; }

    :global(.element07) { animation-delay: 300ms; }

    :global(.element08) { animation-delay: 350ms; }

    :global(.element09) { animation-delay: 400ms; }

    :global(.element10) { animation-delay: 450ms; }
  }

  &.animation06 {
    width: 320px; height: 320px;

    svg { width: 320px; height: 320px; }

    @include mobile {
      width: 160px; height: 160px;
    }
  }

  &.animation06.animating {
    :global(path, g, circle) { transform-origin: 50% 50%; animation: animation6 none 1000ms var(--ui-easing) 0ms; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 50ms; }

    :global(.element03) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 150ms; }

    :global(.element05) { animation-delay: 200ms; }

    :global(.element06) { animation-delay: 250ms; }

    :global(.element07) { animation-delay: 300ms; }

    :global(.element08) { animation-delay: 350ms; }

    :global(.element09) { animation-delay: 400ms; }

    :global(.element10) { animation-delay: 450ms; }
  }

  &.animation07 {
    width: 320px; height: 320px;

    svg { width: 320px; height: 320px; }

    @include mobile {
      width: 160px; height: 160px;
    }
  }

  &.animation07.animating {
    :global(g) { transform-origin: 50% 50%; animation: animation6 none 1000ms var(--ui-easing) 0ms; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 50ms; }

    :global(.element03) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 150ms; }

    :global(.element05) { animation-delay: 200ms; }

    :global(.element06) { animation-delay: 250ms; }

    :global(.element07) { animation-delay: 300ms; }

    :global(.element08) { animation-delay: 350ms; }

    :global(.element09) { animation-delay: 400ms; }

    :global(.element10) { animation-delay: 450ms; }
  }

  &.animation08 {
    width: 400px; height: 400px;

    svg { width: 400px; height: 400px; }

    @include mobile {
      width: 240px; height: 240px;

      svg { margin: -80px 0 0 -80px; }

      :global(.element02a) { opacity: 0 !important; }

      :global(.element02d) { opacity: 0 !important; }

      :global(.element02f) { opacity: 0 !important; }

      :global(.element02h) { opacity: 0 !important; }

      :global(.element03) { opacity: 0 !important; }
    }
  }

  &.animation08.animating {
    :global(g) { transform-origin: 50% 50%; animation: animation6 none 600ms var(--ui-easing) 0ms; }

    :global(.element01) { animation-delay: 200ms; }

    :global(.element02) { animation-delay: 100ms; }

    :global(.element03) { animation-delay: 0ms; }
  }

  // 9

  &.animation09 {
    width: 400px; height: 160px;

    svg { width: 400px; height: 160px; }

    @include mobile {
      width: 240px; height: 160px;
    }
  }

  &.animation09.animating {
    :global(path, g, circle) { animation: animation4 none 500ms var(--ui-easing) 0ms; transform-origin: 50% 50%; }

    :global(.element01) { animation-delay: 0ms; }

    :global(.element02) { animation-delay: 50ms; }

    :global(.element03) { animation-delay: 100ms; }

    :global(.element04) { animation-delay: 150ms; }

    :global(.element05) { animation-delay: 200ms; }

    :global(.element06) { animation-delay: 250ms; }

    :global(.element07) { animation-delay: 300ms; }

    :global(.element08) { animation-delay: 350ms; }

    @include mobile {
      :global(.element01) { animation-delay: 0ms; }

      :global(.element02) { animation-delay: 50ms; }

      :global(.element05) { animation-delay: 100ms; }

      :global(.element06) { animation-delay: 150ms; }


      :global(.element03) { opacity: 0 !important; }

      :global(.element04) { opacity: 0 !important; }

      :global(.element07) { opacity: 0 !important; }

      :global(.element08) { opacity: 0 !important; }
    }
  }

  // 10

  &.animation10 {
    width: 640px; height: 320px;

    svg { width: 640px; height: 320px; }

    @include not-desktop {
      width: 240px; height: 160px;

      svg { margin: 0 0 0 -240px; };

      :global(.element01) { opacity: 0 !important; }

      :global(.element04) { opacity: 0 !important; }

      :global(.element05) { opacity: 0 !important; }

      :global(.element09) { opacity: 0 !important; }

      :global(.element10) { opacity: 0 !important; }

      :global(.element11) { opacity: 0 !important; }

      :global(.element12) { opacity: 0 !important; }

      :global(.element13) { opacity: 0 !important; }

      :global(.element14) { opacity: 0 !important; }

      :global(.element15) { opacity: 0 !important; }

      :global(.element16) { opacity: 0 !important; }

      :global(.element17) { opacity: 0 !important; }

      :global(.element18) { opacity: 0 !important; }

      :global(.element19) { opacity: 0 !important; }

      :global(.element20) { opacity: 0 !important; }
    }
  }

  &.animation10.animating {
      :global(path) { animation: animation4 none 500ms var(--ui-easing) 0ms; transform-origin: 50% 50%; }

      :global(.element01) { animation-delay: 0ms; }

      :global(.element02) { animation-delay: 50ms; }

      :global(.element03) { animation-delay: 100ms; }

      :global(.element04) { animation-delay: 150ms; }

      :global(.element05) { animation-delay: 200ms; }

      :global(.element06) { animation-delay: 250ms; }

      :global(.element07) { animation-delay: 300ms; }

      :global(.element08) { animation-delay: 350ms; }

      :global(.element09) { animation-delay: 400ms; }

      :global(.element10) { animation-delay: 450ms; }

      :global(.element11) { animation-delay: 500ms; }

      :global(.element12) { animation-delay: 550ms; }

      :global(.element13) { animation-delay: 600ms; }

      :global(.element14) { animation-delay: 650ms; }

      :global(.element15) { animation-delay: 700ms; }

      :global(.element16) { animation-delay: 750ms; }

      :global(.element17) { animation-delay: 800ms; }

      :global(.element18) { animation-delay: 850ms; }

      :global(.element19) { animation-delay: 900ms; }

      :global(.element20) { animation-delay: 950ms; }

      @include not-desktop {
        :global(.element02) { animation-delay: 0ms; }

        :global(.element03) { animation-delay: 50ms; }

        :global(.element07) { animation-delay: 150ms; }

        :global(.element08) { animation-delay: 200ms; }
      }
    }
}

@keyframes animation1 {
  0% { transform: translate(0, 0); opacity: 1; }

  40% { transform: translate(40px, -40px); opacity: 0; }

  60% { transform: translate(-40px, 40px); opacity: 0; }

  100% { transform: translate(0, 0); opacity: 1; }
}
@keyframes animation2 {
  0% { transform: translate(0, 0); opacity: 1; }

  40% { transform: translate(-40px, 40px); opacity: 0; }

  60% { transform: translate(40px, -40px); opacity: 0; }

  100% { transform: translate(0, 0); opacity: 1; }
}
@keyframes animation3 {
  0% { transform: translate(0, 0); opacity: 1; }

  40% { transform: translate(50px, 0); opacity: 0; }

  60% { transform: translate(-50px, 0); opacity: 0; }

  100% { transform: translate(0, 0); opacity: 1; }
}
@keyframes animation4 {
  0% { transform: scale(1); }

  30% { transform: scale(0.8) translate(-10px, 10px); }

  40% { transform: scale(1.1); }

  100% { transform: scale(1); }
}
@keyframes animation5 {
  0% { transform: translate(0, 0); }

  40% { transform: translate(0, -20px); }

  60% { transform: translate(0, 20px); }

  100% { transform: translate(0, 0); }
}
@keyframes animation6 {
  0% { transform: scale(1); }

  50% { transform: scale(0.8); }

  100% { transform: scale(1); }
}