@import "@/styles/mixins";

.control-date {
	$element-height: rem(40);

	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; position: relative; z-index: 0; cursor: pointer; min-width: rem(140); flex-basis: rem(140); box-sizing: border-box;

	&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px currentColor; border-radius: 2px; opacity: 0.3; pointer-events: none; }

	&:after {
		content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
		box-shadow: 0 0 0 4px var(--color-focus); border-radius: 2px; opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
	}

	&.focus {
		&:before { opacity: 1 !important; box-shadow: inset 0 0 0 1px var(--color-focus); }

		&:after { opacity: 0.2 !important; }
	}

	&:hover:before { opacity: 1; }

	&.disabled {
		cursor: default;

		&:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
	}

	&.has-error {
		&:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
	}

	&__holder { flex: 1 1 auto; }

	&__icon {
		width: calc($element-height - 2px); height: calc($element-height - 2px); pointer-events: none; background-color: var(--site-bg);
		position: absolute; right: 1px; top: 1px; bottom: 1px;
		display: flex; align-items: center; justify-content: center;

		svg { width: rem(24); height: rem(24); }

		path { fill: var(--color-primary-500); }
	}

	&__input {
		width: 100%; margin: 0; padding: 0 0 0 rem(12); font-size: inherit; background-color: transparent; border: none; box-shadow: none; border-radius: 0; outline: none; appearance: none; height: $element-height; line-height: $element-height; text-align: inherit; flex: 1 1 auto; cursor: pointer;

		&::placeholder { color: rgba(0, 0, 0, 0.3); }

		&::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }
		-webkit-text-fill-color: var(--color-text);
		-webkit-align-items: center;
		-webkit-padding-start: 1px;
		display: -webkit-inline-flex;
		overflow: hidden;

		&::-webkit-datetime-edit {
			-webkit-flex: 1 1 rem(160);
			-webkit-user-modify: read-only !important;
			display: inline-block;
			min-width: rem(110);
			overflow: hidden;
		}

		&::-webkit-datetime-edit-fields-wrapper {
			-webkit-user-modify: read-only !important;
			display: inline-block;
			padding: 0 0 0 rem(12);
			white-space: pre;
		}

		&::-webkit-datetime-edit-ampm-field,
		&::-webkit-datetime-edit-day-field,
		&::-webkit-datetime-edit-hour-field,
		&::-webkit-datetime-edit-millisecond-field,
		&::-webkit-datetime-edit-minute-field,
		&::-webkit-datetime-edit-month-field,
		&::-webkit-datetime-edit-second-field,
		&::-webkit-datetime-edit-week-field,
		&::-webkit-datetime-edit-year-field {
			-webkit-user-modify: read-only !important;
			border: none;
			display: inline;
			font: inherit !important;
			padding: 0;
		}

		&::-webkit-datetime-edit-ampm-field:focus,
		&::-webkit-datetime-edit-day-field:focus,
		&::-webkit-datetime-edit-hour-field:focus,
		&::-webkit-datetime-edit-millisecond-field:focus,
		&::-webkit-datetime-edit-minute-field:focus,
		&::-webkit-datetime-edit-month-field:focus,
		&::-webkit-datetime-edit-second-field:focus,
		&::-webkit-datetime-edit-week-field:focus,
		&::-webkit-datetime-edit-year-field:focus {
			background-color: var(--color-neutral-300);
			color: var(--color-text);
			outline: none;
		}

		&::-webkit-datetime-edit-year-field[disabled],
		&::-webkit-datetime-edit-month-field[disabled],
		&::-webkit-datetime-edit-week-field[disabled],
		&::-webkit-datetime-edit-day-field[disabled],
		&::-webkit-datetime-edit-ampm-field[disabled],
		&::-webkit-datetime-edit-hour-field[disabled],
		&::-webkit-datetime-edit-millisecond-field[disabled],
		&::-webkit-datetime-edit-minute-field[disabled],
		&::-webkit-datetime-edit-second-field[disabled] {
			color: GrayText;
		}

		&::-webkit-datetime-edit-text {
			-webkit-user-modify: read-only !important;
			display: inline;
			font: inherit !important;
		}

		&[type="date"]::-webkit-inner-spin-button,
		&[type="datetime"]::-webkit-inner-spin-button,
		&[type="datetime-local"]::-webkit-inner-spin-button,
		&[type="month"]::-webkit-inner-spin-button,
		&[type="time"]::-webkit-inner-spin-button,
		&[type="week"]::-webkit-inner-spin-button {
			display: inline-block;
			position: static;
			-webkit-margin-start: 0;
		}

		&::-webkit-inner-spin-button {
			appearance: none;
			display: block;
			position: relative;
			cursor: pointer;
			height: 1.5em;
			vertical-align: top;
			-webkit-flex: none;
			-webkit-user-select: none;
			-webkit-user-modify: read-only !important;
		}

		&::-webkit-calendar-picker-indicator {
			display: inline-block;
			padding: 0;
			-webkit-user-modify: read-only !important;
			cursor: pointer;
			position: absolute;
			opacity: 0; left: rem(110); top: 0; bottom: 0; height: $element-height; width: calc(100% - #{rem(110)});
		}

		&::-webkit-calendar-picker-indicator:hover {
			background-color: transparent;
		}

		&::-webkit-date-and-time-value {
			margin-top: 0;
			text-align: left;
			width: 100%;
			padding-left: rem(12);
		}
	}

	&__loader {
		display: flex; flex-direction: row; flex-wrap: nowrap;
		flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;
		padding: 0; align-items: center; justify-content: center;
	}

	@media print { display: none; }
}
