@import "@/styles/mixins";

:import("@/components/ui/ButtonIcon/styles.module.scss") {
  button-icon: button-icon;
}

.list-persons {
  display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: var(--gridunit); @include default-margin; grid-auto-rows: auto;

  &__item {
    flex: 0 0 280px; width: 280px; @include default-margin; margin-right: auto; position: relative;

    &.is-expanded {
      box-shadow: 0 4px 16px 0 rgba(2, 22, 49, 0.12); border-radius: var(--radius-large);

      .list-persons__description { opacity: 1; pointer-events: auto; }

      .list-persons__content { opacity: 0; }
    }
  }

  > LI {
    position: relative; text-overflow: ellipsis; z-index: 0; overflow: hidden;

    &:has(.is-expanded) { box-shadow: 0 4px 16px 0 rgba(2, 22, 49, 0.12); border-radius: var(--radius-large); }
  }

  > LI .list-persons__item { width: 100%; margin: 0 !important; flex: 1 1 auto; }

  &__image {
    position: relative; aspect-ratio: 1; border-radius: var(--radius-large); overflow: hidden; z-index: 0;

    IMG { object-fit: cover; object-position: 50% 0; border-radius: var(--radius-large); overflow: hidden; }

    .button-icon { position: absolute; right: var(--gridunit-tiny); top: var(--gridunit-tiny); z-index: 2; }
  }

  &__content { transition: opacity var(--ui-easing) var(--ui-speed) 0ms; overflow: hidden; text-overflow: ellipsis; }

  &__name { font-weight: var(--medium); margin-top: var(--gridunit-small); font-size: 24px; line-height: 32px; }

  &__position { color: var(--color-neutral-400); }

  &__contacts {
    margin-top: var(--gridunit-small);

    A {
      color: var(--color-link); text-decoration: none;

      &:hover { color: var(--color-link-hover); text-decoration: underline; }
    }

    > LI {
      overflow: hidden; text-overflow: ellipsis; white-space: nowrap;

      + LI { margin-top: var(--gridunit-tiny); }
    }
  }

  &__description {
    transition: opacity var(--ui-easing) var(--ui-speed) 0ms;
    position: absolute; left: 0; top: 0; bottom: 0; right: 0; z-index: 3; opacity: 0; pointer-events: none;
    padding: var(--gridunit-tiny); background-color: var(--color-white); overflow: hidden; border-radius: var(--radius-large);
    display: flex; flex-direction: column; gap: 1em;

    &__button { display: flex; align-items: center; justify-content: flex-end; flex: 0 0 auto; }

    &__text {
      flex: 1 1 auto; display: flex; flex-direction: column; gap: 1em; overflow: hidden; padding: 0 var(--gridunit-tiny) var(--gridunit-tiny) var(--gridunit-tiny);
      overflow-y: auto; @include scrollbars(false);
      
      P { flex: 0 0 auto; margin: 0; overflow: hidden; text-overflow: ellipsis; }
    }
  }

  @include mobile { grid-template-columns: 1fr 1fr; gap: var(--gridunit-small); }
  @include tablet { grid-template-columns: 1fr 1fr 1fr; }
}