@import "@/styles/mixins";

.control-checkbox {
  flex: 0 0 auto; position: relative; display: inline-block; width: 16px; height: 16px; z-index: 0;

  &:before { content: ""; position: absolute; left: -5px; top: -5px; right: -5px; bottom: -5px; background-color: transparent; }

  &__overflow {
    position: absolute; left: 0; right: 0; top: 0; bottom: 0;

    SPAN {
      position: absolute; left: 0; right: 0; top: 0; bottom: 0; transition: all var(--ui-speed) var(--ui-easing) 0ms; border-radius: 2px;

      svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); opacity: 0; margin: 0 !important; }

      path { fill: currentColor; }

      &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px var(--color-neutral-400); border-radius: 2px; pointer-events: none; }

      &:after {
        content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
        box-shadow: 0 0 0 4px var(--color-focus); border-radius: 2px; opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
      }

      @media print { border: 1px solid currentColor !important; }
    }

    INPUT {
      position: absolute; right: 100%; bottom: 100%; pointer-events: none; opacity: 0;

      &:checked + SPAN {
        background-color: var(--color-primary-500); color: var(--color-white);

        &:before { box-shadow: inset 0 0 0 1px var(--color-primary-500); }

        svg { opacity: 1; }

        path { fill: var(--color-white); }
      }

      &:disabled + SPAN,
      &[disabled] + SPAN {
        opacity: 0.5;
      }

      &:focus + SPAN {
        &:before { box-shadow: inset 0 0 1px var(--color-neutral-800); }

        &:after { opacity: 0.2; }
      }
    }

    &.has-error {
      SPAN {
        &:before { box-shadow: inset 0 0 0 1px var(--color-danger); opacity: 1; }

        path { fill: var(--color-danger); }
      }

      INPUT:checked + SPAN {
        &:before { background-color: var(--color-danger); box-shadow: inset 0 0 0 1px var(--color-danger); }
      }
    }
  }
}
