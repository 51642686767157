@import "@/styles/mixins";

.toaster {
  position: relative; pointer-events: none;

  &__list {
    display: flex; flex-direction: column; align-items: flex-end; justify-content: flex-end;
    position: absolute; left: 0; right: 0; bottom: 0;

    &:empty { display: none; }

    > LI { flex: 0 0 auto; width: 100%; max-width: 450px; }
  }

}
